import { ComponentType, PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { OverlayRef, Overlay, GlobalPositionStrategy } from '@angular/cdk/overlay';
import { Injector, ComponentRef, Injectable } from '@angular/core';
import { VERTICAL_TOOLBAR_DATA, VerticalToolbarConfig, VerticalToolbarComponent } from '../vertical-toolbar.component';

// https://mentormate.com/blog/create-modal-window-with-angular-cdk/
@Injectable()
export class VerticalToolbar {
  constructor(
    private overlay: Overlay,
    private hostComponent:Injector
  ) {}

  open(component: ComponentType<VerticalToolbarComponent>, config: VerticalToolbarConfig) {
    // Returns an OverlayRef (which is a PortalHost)
    const overlayRef = this.createOverlay();
    const overlayComponent = this.attachModalContainer(overlayRef, component, config);

    overlayComponent.buttonClickedEvent.subscribe(() => overlayRef.dispose());
  }

  private attachModalContainer<T>(
   overlayRef: OverlayRef, 
   component: ComponentType<VerticalToolbarComponent>,
   config: VerticalToolbarConfig
   ) {
    const injector = this.createInjector(config);
    const containerPortal = new ComponentPortal(component, null, injector);
    const containerRef: ComponentRef<VerticalToolbarComponent> = overlayRef.attach(containerPortal);

    overlayRef.backdropClick()
      .subscribe(() => overlayRef.dispose());

    return containerRef.instance;
  }

  private createOverlay(): OverlayRef {
    // these settings can be passed in the config too
    let positionStrategy = new GlobalPositionStrategy();
    positionStrategy.centerVertically();
    positionStrategy.right("0px");

    return this.overlay.create({
      hasBackdrop: true,
      positionStrategy: positionStrategy
    });
  }

  private createInjector(config: VerticalToolbarConfig): Injector {
    // create injector
    const injectorTokens = new WeakMap();
    injectorTokens.set(VERTICAL_TOOLBAR_DATA, config);
    return new PortalInjector(this.hostComponent, injectorTokens);
  }
}