import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import * as fromAuthActions from '../../../store/actions/auth.actions';
import { Store } from '@ngrx/store';
import * as fromAppState from '../../../store';

@Component({
	selector: 'app-login',
	styleUrls: ['./login.component.scss'],
	templateUrl: './login.component.html'
})
export class LoginComponent {
	constructor(private store: Store<fromAppState.AppState>) { }

	onLogin(form: NgForm) {
		this.store.dispatch(new fromAuthActions.TryLogin({ email: form.value.email, password: form.value.password }));
	}
}
