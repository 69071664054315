import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil, map, debounceTime, switchMap } from 'rxjs/operators';
import { NoteSearch } from '@shared/models/note-search.model';
import { NoteSearchService } from '../../providers/note-search.service';
import { Router } from '@angular/router';
import { MatAutocompleteSelectedEvent } from '@angular/material';

@Component({
	selector: 'app-notes-search',
	styleUrls: ['./notes-search.component.scss'],
	templateUrl: './notes-search.component.html'
})
export class NotesSearchComponent implements OnInit, OnDestroy {
	constructor(
		private noteSearchService: NoteSearchService,
		private router: Router
	) {}

	@ViewChild('searchInput', { static: true }) searchInput: ElementRef;
	options: NoteSearch[] = [];
	onDestroy$ = new Subject<void>();

	ngOnInit() {
		fromEvent(this.searchInput.nativeElement, 'input')
			.pipe(
				map((i: any) => i.currentTarget.value),
				debounceTime(500),
				switchMap((value: string) => this.noteSearchService.search(value)),
				takeUntil(this.onDestroy$)
			)
			.subscribe(searchItems => {
				this.options = searchItems || [];
			});
	}

	openItem(event: MatAutocompleteSelectedEvent) {
		if (event.option && event.option.value.url) {
			this.router.navigateByUrl(event.option.value.url);
		}
	}

	displayWith(selectedOption: NoteSearch) {
		return selectedOption.name;
	}

	clearSearchBtnClicked() {
		this.searchInput.nativeElement.value = '';
		this.options = [];
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
