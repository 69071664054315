import { createSelector } from '@ngrx/store';
import { NoteBookState } from '../reducers/note-book.reducer';
import { noteCollectionFeature, NoteCollectionState } from '..';

export const getNoteBooksState = createSelector(
  noteCollectionFeature,
  (state: NoteCollectionState) => state.noteBooks
)

export const getNoteBookEntities = createSelector(
  getNoteBooksState,
  (state: NoteBookState) => state.entities
);

export const getNoteBooksAsArray = createSelector(
  getNoteBookEntities,
  noteBookEntities => noteBookEntities ? Object.values(noteBookEntities) : []
);

export const getSelectedNoteBookId = createSelector(
  getNoteBooksState,
  (state: NoteBookState) => state.entities[state.selectedNoteBookId] ? state.selectedNoteBookId : undefined
);
