import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject, Observable } from 'rxjs';
import { DialogButton } from '@shared/models/dialog-button.model';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  constructor(
    private dialogref: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.message) {
      this.message = data.message;
    }
  }

  message: string = "";
  private onCancel$: Subject<void> = new Subject<void>();
  private onConfirm$: Subject<void> = new Subject<void>();

  private cancelButton: DialogButton = {
    text: 'Cancel',
    action: () => {
      this.onCancel$.next();
      this.dialogref.close();
    }
  };

  private confirmButton: DialogButton = {
    text: 'Confirm',
    action: () => {
      this.onConfirm$.next();
      this.dialogref.close();
    }
  };

  actionButtons: DialogButton[] = [
    this.cancelButton,
    this.confirmButton
  ];

  onConfirm(): Observable<void> {
    return this.onConfirm$.asObservable();
  }

  onCancel(): Observable<void> {
    return this.onCancel$.asObservable();
  }
}
