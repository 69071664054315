import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-notification-message',
  templateUrl: './notification-message.component.html',
  styleUrls: ['./notification-message.component.scss']
})
export class NotificationMessageComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackbar: MatSnackBar
  ) {
    this.message = data.message || '';
    this.type = data.type;
  }

  message: string = '';
  type: string;

  closeSnackbar() {
    this.snackbar.dismiss();
  }
}
