import * as firebase from 'firebase/app';
import 'firebase/storage';

class FirebaseUploadAdapter {
  constructor(private loader) {
    // The file loader instance to use during the upload. It sounds scary but do not
    // worry — the loader will be passed into the adapter later on in this guide.
  }

  // Starts the upload process.
  upload() {
    return this.loader.file
      .then(file => new Promise((resolve, reject) => {
        (<any>window).fireAngularEvent('imageNoteDataRequested', (noteData) => {
          resolve({ file, noteData });
        });
      }))
      .then(noteFileData => new Promise((resolve, reject) => {
        // first upload the file to the firebase storage, this will give us the url of the uploaded file
        const fileName = this.uuidv4() + "_" + noteFileData.file.name;
        const fileInfo = {
          fileName,
          file: noteFileData.file,
          noteBookId: noteFileData.noteData.noteBookId,
          noteId: noteFileData.noteData.noteId
        };
        this.uploadToFirebase(fileInfo, resolve, reject);
      }))
      .then(fileInfo => new Promise( ( resolve, reject ) => {
        // save the metadata of the file in the firebase database
        (<any>window).fireAngularEvent('imageUploadRequested', fileInfo);
        resolve(fileInfo);
      }))
      .then(fileInfo => new Promise( ( resolve, reject ) => {
        resolve({
          default: fileInfo.url
        });
      }));
  }

  // Aborts the upload process.
  abort() {
    console.log("uploaded will be aborted");
  }

  /**
   * Uplaods the file to the firebase storage.
   * @param fileInfo 
   * @param resolve 
   * @param reject 
   */
  private uploadToFirebase(fileInfo, resolve, reject) {
    var userId = firebase.auth().currentUser ? firebase.auth().currentUser.uid : null;
    if (!userId) {
      reject("Please log in again to upload the file");
    }
    else if(!fileInfo.noteId) {
      reject("No Note Id was provided");
    }
    else {
      var uploadTask = firebase.storage().ref(userId + '/' + fileInfo.fileName).put(fileInfo.file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        snapshot => this.fileUploadStateChanged(snapshot),
        error => reject(error),
        () => this.fileUploadComplete(uploadTask, fileInfo, resolve)
      );
    }
  }

  /**
   * Triggered when the uplaod file status changes.
   * @param snapshot Information regarding the file uplaod state.
   */
  private fileUploadStateChanged(snapshot: firebase.storage.UploadTaskSnapshot) {
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
    console.log('Upload is ' + progress + '% done');

    switch (snapshot.state) {
      case firebase.storage.TaskState.PAUSED: // or 'paused'
        console.log('Upload is paused');
        break;
      case firebase.storage.TaskState.RUNNING: // or 'running'
        console.log('Upload is running');
        break;
    }
  }

  /**
   * Triggered when the file upload completes successfully.
   * @param uploadTask 
   * @param fileInfo 
   * @param resolve 
   */
  private fileUploadComplete(uploadTask: firebase.storage.UploadTask, fileInfo, resolve) {
    // Handle successful uploads on complete
    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
    uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {          
      resolve({
        ...fileInfo,
        url: downloadURL
      });
    });
  }

  /**
   * Creates a uuid.
   */
  private uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}

/**
 * Creates a Custom File Uplaod Adapter Plugin for the CkEditor.
 * @param editor 
 */
export function FirebaseFileUploadAdapterPlugin( editor ) {
  editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
      // Configure the URL to the upload script in your back-end here!
      return new FirebaseUploadAdapter( loader );
  };
}
