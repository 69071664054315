import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { NoteService } from '../providers/note.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { NoteBookService } from '../providers/note-book.service';
import { Store } from '@ngrx/store';
import * as fromStore from '../store/index';
import * as fromNoteActions from '../store/actions/note.actions';
import { NoteBook } from '@shared/models/note-book.model';

@Injectable()
export class NoteItemPrefetchGuard implements CanActivate {
  constructor(
    private noteService: NoteService,
    private noteBookService: NoteBookService,
    private store: Store<fromStore.NoteCollectionState>,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const noteId = route.params['noteId'];

    return this.noteService.getNoteById(noteId)
      .pipe(
        switchMap(note => {
          if (note) {
            this.store.dispatch(new fromNoteActions.EditNote(note));
            return this.noteBookService.getNoteBookById(note.noteBookId);
          }
          return of(null);
        }),
        map((noteBook: NoteBook) => {
          if (noteBook) {
            route.data = { ...route.data, noteBook };
          }
          return !!noteBook;
        })
      );
  }
}