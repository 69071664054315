import { Component, OnInit, OnDestroy } from '@angular/core';

import * as fromAuthActions from '../../store/actions/auth.actions';
import { Store } from '@ngrx/store';
import * as fromAppState from '../../store';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { AuthState } from 'src/app/store/reducers/auth.reducer';
import { NoteBookService } from 'src/app/modules/home/providers/note-book.service';

@Component({
	selector: 'app-header',
	styleUrls: ['./header.component.scss'],
	templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy {
	isLoggedIn$: Observable<boolean>;
	onDestroy$ = new Subject();

	constructor(private store: Store<fromAppState.AppState>, private notesService: NoteBookService) { }

	ngOnInit() {
		this.isLoggedIn$ = this.store.select('auth').pipe(
			takeUntil(this.onDestroy$),
			map((authState: AuthState) => {
				return authState.isAuthenticated;
			})
		);
	}

	logout() {
		this.store.dispatch(new fromAuthActions.TryLogout());
	}

	saveData() {
		this.notesService.saveNoteBooks();
	}

	ngOnDestroy() {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}
}
