import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import * as fromAuthActions from '../actions/auth.actions';
import * as firebase from 'firebase/app';
import 'firebase/database';
import { from } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/core/providers/auth.service';
import { LoginData } from '@shared/models/login.model';

@Injectable()
export class AuthEffects {
  @Effect()
  authLogin$ = this.actions$.pipe(
    ofType(fromAuthActions.LoginActionTypes.TRY_LOGIN),
    map((action: fromAuthActions.TryLogin) => action.payload),
    switchMap((loginData: LoginData) => {
      return this.authService.login(loginData);
    }),
    map((token: string) => {
      this.router.navigate(['/']);
      return new fromAuthActions.Login(token);
    })
  );

  @Effect()
  authRegister$ = this.actions$.pipe(
    ofType(fromAuthActions.LoginActionTypes.TRY_REGISTER),
    map((action: fromAuthActions.TryRegister) => action.payload),
    switchMap((registerData: { email: string, password: string }) => {
      return from(firebase.auth().createUserWithEmailAndPassword(registerData.email, registerData.password));
    }),
    switchMap((user) => {
      return from(firebase.auth().currentUser.getIdToken());
    }),
    map((token: string) => {
      this.router.navigate(['/']);
      return new fromAuthActions.Register(token);
    })
  );

  @Effect()
  authLogout$ = this.actions$.pipe(
    ofType(fromAuthActions.LoginActionTypes.TRY_LOGOUT),
    switchMap(() => {
      return from(firebase.auth().signOut());
    }),
    map(() => {
      this.router.navigate(['/login']);
      return new fromAuthActions.Logout();
    })
  );

  @Effect()
  setExistingToken$ = this.actions$.pipe(
    ofType(fromAuthActions.LoginActionTypes.SET_EXISTING_TOKEN),
    map((action: fromAuthActions.SetExistingToken) => action.payload),
    map(token => {
      return new fromAuthActions.Login(token);
    })
  );

  constructor(private actions$: Actions, private router: Router, private authService: AuthService) {
    console.log('Auth efect initialized');
  }
}
