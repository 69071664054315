import { NoteActions, NoteActionTypes } from '../actions/note.actions';
import { Note } from '@shared/models/note.model';

export interface NoteState {
  entities: { [id: string]: Note };
  selectedNoteId: string;
  editNote: Note;
}

export const initialState: NoteState = {
  entities: {},
  selectedNoteId: undefined,
  editNote: null
};

export function NoteReducer(state = initialState, action: NoteActions) {
  switch (action.type) {
    case NoteActionTypes.LOAD_NOTES_SUCCESS: {
      let entities = {};
      action.notes.forEach(n => entities[n.id] = {...n});

      return {
        ...state,
        entities
      };
    }

    case NoteActionTypes.NOTE_ADDED: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.note.id]: { ... action.note }
        }
      }
    }

    case NoteActionTypes.NOTE_SELECTED: {
      return {
        ...state,
        selectedNoteId: action.id
      }
    }

    case NoteActionTypes.NOTE_DELETED: {
      const { [action.id]: undefined,  ...entities } = state.entities;

      return {
        ...state,
        entities
      }
    }

    case NoteActionTypes.NOTE_UPDATED: {
      const entities = {
        ...state.entities,
        [action.note.id]: { ...action.note }
      };

      return {
        ...state,
        entities
      }
    }

    case NoteActionTypes.EDIT_NOTE: {
      const editNote = { ...action.note };

      return {
        ...state,
        editNote
      }
    }
  }
  
  return state;
}