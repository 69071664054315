import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { take, takeUntil, map, first, switchMap } from 'rxjs/operators';
import { NoteService } from '../../providers/note.service';
import * as fromStore from '../../store';
import * as fromNoteSelectors from '../../store/selectors/note.selectors';
import * as fromNoteActions from '../../store/actions/note.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material';
import { NoteBook } from '@shared/models/note-book.model';
import { VerticalToolbar } from '@shared/vertical-toolbar/providers/vertical-toolbar';
import { BreadCrumbItem, ToolbarButton } from '@shared/toolbar/toolbar.component';
import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { VerticalToolbarComponent } from '@shared/vertical-toolbar/vertical-toolbar.component';
import { Note } from '@shared/models/note.model';

@Component({
  selector: 'app-note-list',
  styleUrls: ['./note-list.component.scss'],
  templateUrl: './note-list.component.html'
})
export class NoteListComponent implements OnDestroy {
  noteItems$: Observable<Note[]>;
  selectedNoteId$: Observable<string>;
  noteBook: NoteBook;
  numOfColumns: number;
  gutterSize: number;
  rowHeight: string;

  constructor(
    private dialog: MatDialog,
    private notesService: NoteService,
    private store: Store<fromStore.NoteCollectionState>,
    private route: ActivatedRoute,
    private router: Router,
    private breakPoint: BreakpointObserver,
    private verticalToolbar: VerticalToolbar
  ) { }

  private doubleClickTimer;
  private onDestroy$: Subject<void> = new Subject<void>();
  isSmallScreen = this.breakPoint.isMatched('(max-width: 450px)');
  
  breadcrumbs: BreadCrumbItem[] = [
    {
      text: 'NoteBooks',
      link: '/notebook'
    }
  ];

  private isNoteSelected$ = this.store.select(fromNoteSelectors.getSelectedNoteId)
    .pipe(
      map(noteBookId => !!noteBookId),
      takeUntil(this.onDestroy$)
    );

  private opnBtn: ToolbarButton = {
    text: 'Open',
    icon: 'notes',
    hidden$: this.isNoteSelected$.pipe(map(n => !n)),
    action: () => this.openNote()
  };

  private deleteButton: ToolbarButton = {
    text: 'Delete',
    icon: 'delete',
    hidden$: this.isNoteSelected$.pipe(
      map(isSelected => !isSelected),
      takeUntil(this.onDestroy$)
    ),
    action: () => this.deleteNote()
  }

  toolbarButtons: ToolbarButton[] = [
    this.opnBtn,
    this.deleteButton
  ];

  ngOnInit() {
    this.route.data
      .pipe(
        map(data => data.noteBook as NoteBook),
        takeUntil(this.onDestroy$)
      )
      .subscribe(noteBook => {
        if (noteBook) {
          this.notesService.setUpDataLoadEvents(noteBook.id);
          this.breadcrumbs.push({
            text: noteBook.name,
            link: `/notebook/${noteBook.id}`
          })
        }
      });

    this.noteItems$ = this.store.select(fromNoteSelectors.getNotesAsArray)
      .pipe(takeUntil(this.onDestroy$));
    this.selectedNoteId$ = this.store.select(fromNoteSelectors.getSelectedNoteId)
      .pipe(takeUntil(this.onDestroy$));

    this.calculateNumberOfColumns();
  }

  noteSelected(id: string) {
    if (id) {
      this.store.select(fromNoteSelectors.getSelectedNoteId)
        .pipe(take(1))
        .subscribe(selectedNoteId => {
          if (selectedNoteId !== id) {
            this.store.dispatch(new fromNoteActions.NoteSelected(id));
          }
        });
    }
  }

  addNote() {
    this.router.navigateByUrl("/add-note");
  }

  deleteNote() {
    var dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      height: '174px',
      data: {
        message: "Delete this note?"
      }
    });

    dialogRef.componentInstance.onConfirm()
      .pipe(
        switchMap(() => this.selectedNoteId$),
        first(),
        takeUntil(this.onDestroy$)
      )
      .subscribe(noteId => {
        if (noteId) {
          this.store.dispatch(new fromNoteActions.DeleteNote(noteId));
        }
      });
  }

  itemClicked(id: string) {
    if (this.doubleClickTimer || this.isSmallScreen) {
      // double click
      clearTimeout(this.doubleClickTimer);
      this.doubleClickTimer = null;
      this.noteSelected(id);
      this.openNote();
    }
    else {
      // single click
      this.noteSelected(id);
      this.doubleClickTimer = setTimeout(() => {
        this.doubleClickTimer = null;
      }, 250);
    }
  }

  openNote() {
    this.selectedNoteId$
      .pipe(take(1))
      .subscribe(noteId => {
        if (noteId) {
          this.router.navigateByUrl(`/note/${noteId}`);
        }
      })
  }

  openVerticalToolbar(event: Event, noteId: string) {
    event.stopPropagation();

    this.store.dispatch(new fromNoteActions.NoteSelected(noteId));

    this.verticalToolbar.open(VerticalToolbarComponent, {
      buttons: [
        {
          icon: 'notes',
          action: () => this.openNote()
        },
        {
          icon: 'delete',
          action: () => this.deleteNote()
        }
      ]
    });
  }

  calculateNumberOfColumns() {
    if (window.innerWidth < 450) {
      this.numOfColumns = 1;
      this.gutterSize = 5;
      this.rowHeight = '6:1';
    }
    else {
      this.numOfColumns = 3;
      this.gutterSize = 16;
      this.rowHeight = '4:1';
    }
  }

  onResize() {
    this.calculateNumberOfColumns();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
