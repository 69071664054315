import { createSelector } from '@ngrx/store';
import { noteCollectionFeature, NoteCollectionState } from '..';
import { NoteState } from '../reducers/note.reducer';

export const getNotesState = createSelector(
  noteCollectionFeature,
  (state: NoteCollectionState) => state.notes
)

export const getNoteEntities = createSelector(
  getNotesState,
  (state: NoteState) => state.entities
);

export const getNotesAsArray = createSelector(
  getNoteEntities,
  noteEntities => noteEntities ? Object.values(noteEntities) : []
);

export const getSelectedNoteId = createSelector(
  getNotesState,
  (state: NoteState) => state.entities[state.selectedNoteId] ? state.selectedNoteId : undefined
);

export const getSelectedNote = createSelector(
  getNotesState,
  (state: NoteState) => state.entities[state.selectedNoteId]
);

export const getEditNote = createSelector(
  getNotesState,
  (state: NoteState) => state.editNote
);
