import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import * as fromAuthActions from '../../../store/actions/auth.actions';
import { Store } from '@ngrx/store';
import * as fromAppState from '../../../store';

@Component({
    selector: 'app-register',
    styleUrls: ['./register.component.scss'],
    templateUrl: './register.component.html'
})
export class RegisterComponent {
    constructor(private store: Store<fromAppState.AppState>) { }

    onRegister(form: NgForm) {
        this.store.dispatch(new fromAuthActions.TryRegister({ email: form.value.email, password: form.value.password }));
    }
}
