import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/providers/auth.service';
import { Observable, from, throwError, Subject, of } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/database';
import { first, switchMap, take, map, catchError, tap } from 'rxjs/operators';
import { GlobalEventService } from 'src/app/providers/global-event.service';
import { NoteImageMetaData } from '@shared/models/note-image-meta-data.model';
import { Note } from '@shared/models/note.model';

@Injectable()
export class NoteImageService {
  constructor(
    private authService: AuthService,
    private globalEventService: GlobalEventService
  ) {
    this.globalEventService.subscribe('imageUploadRequested', this.addNoteImageMetaData, this);
    this.globalEventService.subscribe('imageNoteDataRequested', this.imageNoteDataRequested, this);
  }

  noteBookId: string;
  noteId: string;

  addNoteImageMetaData(fileInfo: { fileName: string, file: any, url: string}) {
    this.authService.onAuthentication()
      .pipe(first())
      .subscribe(() => {
        const userId = firebase.auth().currentUser.uid;
        const imageRef = this.imageMetaDataRef(userId, this.noteId);
        const newKey = imageRef.push().key;
        const imageMetaData: NoteImageMetaData = {
          id: newKey,
          name: fileInfo.fileName,
          noteBookId: this.noteBookId,
          noteId: this.noteId,
          url: fileInfo.url
        };
        const updates = {};
        updates[newKey] = imageMetaData;

        return from(imageRef.update(updates).then((snapshot) => {
            return snapshot;
          })).pipe(
            take(1),
            map(() => imageMetaData),
            catchError(error => throwError(error))
          );
      });
  }

  imageNoteDataRequested(resolveHandler) {
    resolveHandler({
      noteBookId: this.noteBookId,
      noteId: this.noteId
    });
  }

  openedNoteInfo(noteBookId: string, noteId: string) {
    this.noteBookId = noteBookId;
    this.noteId = noteId;
  }

  updateImageLinksForNoteBook(note: Note): Observable<void> {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(note.content, 'text/html');
    const images = Array.from(parsedHtml.images);

    console.log('updating images for note: ' + note.id);

    return this.getImageMetaDataByNoteId(note.id)
      .pipe(
        first(),
        map(savedImageMetaData => {
          savedImageMetaData = savedImageMetaData || [];
          // find images that were deleted
          const imagesDeleted: NoteImageMetaData[] = [];

          savedImageMetaData.forEach(savedImg => {
            if (!images.find(img => img.src === savedImg.url)) {
              imagesDeleted.push(savedImg);
            }
          });

          return imagesDeleted;
        }),
        switchMap(imagesDeleted => {
          // remove the deleted images from the storage
          const userId = firebase.auth().currentUser.uid;
          return this.removeImagesFromNote(userId, imagesDeleted);
        }),
        tap(() => console.log('updated all images for note' + note.id))
      );
  }

  deleteAllImagesForNote(noteId: string): Observable<void> {
    // it is kind of like updating the note images, except that all images will be removed
    const deleteNote = {
      ...new Note(),
      id: noteId,
      content: '' // empty content to simulate all images have been deleted
    };
    return this.updateImageLinksForNoteBook(deleteNote);
  }

  deleteAllImagesForNoteList(notes: Note[]): Observable<void> {
    notes = notes || [];
    const result: Subject<void> = new Subject<void>();
    const allDeletionTasks: Promise<any>[] = [];

    console.log('deleting all images for the provided notes list');

    if (!notes.length) {
      return of(null);
    }

    Promise.all(
      notes.map(n => new Promise((resolve, reject) => {
        this.deleteAllImagesForNote(n.id)
          .pipe(
            first(),
            catchError(error => {
              console.log('all images deleted from note list failed', error);
              reject(error);
              return throwError(error);
            })
          )
          .subscribe(() => {
            console.log('all images deleted from note: ' + n.id);
            resolve();
          });
      }))
    )
      .then(() => {
        console.log('all images deleted from all the notes in the list');
        result.next();
      })
      .catch(error => {
        console.log('all images NOT deleted from all the notes in the list');
        return throwError(error);
      });

    return result.asObservable();
  }

  private getImageMetaDataByNoteId(noteId: string): Observable<NoteImageMetaData[]> {
    return this.authService
      .onAuthentication()
      .pipe(
        first(),
        switchMap(() => {
          const userId = firebase.auth().currentUser.uid;
          const imageRef = this.imageMetaDataRef(userId, noteId);

          return from(imageRef.once('value').then((snapshot) => {
            const imageData = snapshot.val() && Object.values(snapshot.val());
            const imageMetaData = imageData && imageData as NoteImageMetaData[] || [];
  
            return imageMetaData;
          }))
          .pipe(first());
        })
      );
  }

  private imageMetaDataRef(userId: string, noteId: string): firebase.database.Reference {
    return firebase.database().ref('/users/' + userId + '/note-image-meta-data/' + noteId);
  }

  private imageStorageRef(userId: string): firebase.storage.Reference {
    return firebase.storage().ref(userId);
  }

  private removeImagesFromNote(userId: string, noteImageMetaData: NoteImageMetaData[]): Observable<void> {
    if (userId) {
      const result = new Subject<void>();
      const imageStorageRef = this.imageStorageRef(userId);
      const allDeletionTasks: Promise<any>[] = [];

      if (!noteImageMetaData.length) {
        console.log('No image to remove');
        return of(null);
      }

      noteImageMetaData.forEach(img => {
        const imageMetaDataRef = this.imageMetaDataRef(userId, img.noteId);
        const deletion: Promise<void> = imageStorageRef.child(`${img.name}`)
        .delete()
        .then(() => {
          console.log('Image removed from stotage, now removing from database ' + img.name);
          return imageMetaDataRef.child(img.id).remove()
        });

        allDeletionTasks.push(deletion);
      });

      Promise.all(allDeletionTasks)
        .then(() => {
          console.log('removed all images successfully from the database');
          return result.next()
        })
        .catch(error => throwError("Failed to delete image data", error));

        return result.asObservable();
    }
    else {
      return throwError("No user id or note id found")
    }
  }
}
