import { Component, InjectionToken, Inject, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
export const VERTICAL_TOOLBAR_DATA = new InjectionToken<{}>('VERTICAL_TOOLBAR_DATA');

export interface VerticalToolbarButton {
  icon: string;
  hidden$?: Observable<boolean>;
  action: () => void;
}

export interface VerticalToolbarConfig {
  buttons: VerticalToolbarButton[];
}

@Component({
  selector: 'app-vertical-toolbar',
  templateUrl: './vertical-toolbar.component.html',
  styleUrls: ['./vertical-toolbar.component.scss']
})
export class VerticalToolbarComponent {
  constructor(
    @Inject(VERTICAL_TOOLBAR_DATA) public data: any
  ) {
    if (data) {
      this.buttons = data.buttons || [];
    }
  }

  buttonClickedEvent = new EventEmitter<void>();
  buttons: VerticalToolbarButton[] = [];

  buttonClicked(button: VerticalToolbarButton) {
    if (button) {
      this.buttonClickedEvent.emit();
      button.action();
    }
  }
}
