import { MatSnackBar } from '@angular/material';
import { NotificationMessageComponent } from './notification-message.component';
import { Injectable } from '@angular/core';

@Injectable()
export class NotificationMessageService {
  constructor(private snackbar: MatSnackBar) {}

  showSuccess(message: string) {
    this.show(message, 'success');
  }

  showError(message: string) {
    this.show(message, 'error');
  }

  private show(message: string, type: string) {
    this.snackbar.openFromComponent(NotificationMessageComponent, {
      duration: 10000, // 10 seconds
      horizontalPosition: "center",
      verticalPosition: "bottom",
      data: {
        message,
        type
      }
    });
  }
}