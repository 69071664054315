import { Action } from '@ngrx/store';
import { LoginData } from '@shared/models/login.model';

export enum LoginActionTypes {
  LOGIN = '[AUTH] LOGIN',
  LOGOUT = '[AUTH] LOGOUT',
  REGISTER = '[AUTH] REGISTER',
  TRY_LOGIN = '[AUTH] TRY_LOGIN',
  TRY_LOGOUT = '[AUTH] TRY_LOGOUT',
  TRY_REGISTER = '[AUTH] TRY_REGISTER',
  SET_EXISTING_TOKEN = '[AUTH] SET_EXISTING_TOKEN'
}

export class Login implements Action {
  readonly type = LoginActionTypes.LOGIN;

  constructor(public payload: string) { }
}

export class Logout implements Action {
  readonly type = LoginActionTypes.LOGOUT;
}

export class Register implements Action {
  readonly type = LoginActionTypes.REGISTER;

  constructor(public payload: string) { }
}

export class TryLogin implements Action {
  readonly type = LoginActionTypes.TRY_LOGIN;

  constructor(public payload: LoginData) { }
}

export class TryLogout implements Action {
  readonly type = LoginActionTypes.TRY_LOGOUT;
}

export class TryRegister implements Action {
  readonly type = LoginActionTypes.TRY_REGISTER;

  constructor(public payload: { email: string, password: string }) { }
}

export class SetExistingToken implements Action {
  readonly type = LoginActionTypes.SET_EXISTING_TOKEN;

  constructor(public payload: string) { }
}

export type LoginActions = Login | Logout | Register | TryLogin | TryLogout | TryRegister | SetExistingToken;
