import { NoteBookActions, NoteBookActionTypes } from '../actions/note-book.actions';
import { NoteBook } from '@shared/models/note-book.model';

export interface NoteBookState {
  entities: { [id: string]: NoteBook };
  selectedNoteBookId: string;
}

export const initialState: NoteBookState = {
  entities: {},
  selectedNoteBookId: undefined
};

export function NoteBookReducer(state = initialState, action: NoteBookActions) {
  switch (action.type) {
    case NoteBookActionTypes.LOAD_NOTEBOOKS_SUCCESS: {
      let entities = {};
      action.notebooks.forEach(n => entities[n.id] = {...n});

      return {
        ...state,
        entities
      };
    }

    case NoteBookActionTypes.NOTEBOOK_ADDED: {
      return {
        ...state,
        entities: {
          ...state.entities,
          [action.notebook.id]: { ... action.notebook }
        }
      }
    }

    case NoteBookActionTypes.NOTEBOOK_SELECTED: {
      return {
        ...state,
        selectedNoteBookId: action.id
      }
    }

    case NoteBookActionTypes.NOTEBOOK_DELETED: {
      const { [action.id]: undefined,  ...entities } = state.entities;

      return {
        ...state,
        entities
      }
    }
  }
  
  return state;
}