import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

export interface ToolbarButton {
  text: string;
  icon: string;
  hidden$?: Observable<boolean>;
  disabled$?: Observable<boolean>;
  action: () => void;
}

export interface BreadCrumbItem {
  text: string;
  link?: string;
}

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent {
  constructor(private router: Router) {}

  @Input() buttons: ToolbarButton[] = [];
  @Input() breadcrumbs: BreadCrumbItem[] = [];

  crumbClicked(url: string) {
    if (url) {
      this.router.navigateByUrl(url);
    }
  }
}
