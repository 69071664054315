import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './containers/home/home.component';
import { NotesSearchComponent } from './components/notes-search/notes-search.component';
import { NoteBookComponent } from './components/note-book/note-book.component';
import { NoteBookService } from './providers/note-book.service';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule, MatSelectModule, MatCardModule } from '@angular/material';
import { AddNoteBookComponent } from './components/add-note-book/add-note-book.component';
import { StoreModule } from '@ngrx/store';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { reducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { NoteBookEffects } from './store/effects/note-book.effects';
import { NoteListComponent } from './containers/note-list/note-list.component';
import { HomeRoutingModule } from './home-routing.module';
import { NoteService } from './providers/note.service';
import { AddNoteItemComponent } from './containers/add-note-item/add-note-item.component';
import { NoteBookPrefetchGuard } from './guards/note-book-prefetch.guard';
import { NoteEffects } from './store/effects/note.effects';
import { NoteDetailsComponent } from './containers/note-details/note-details.component';
import { NoteItemPrefetchGuard } from './guards/note-prefetch.guard';
import { PortalModule } from '@angular/cdk/portal';
import { OverlayModule } from '@angular/cdk/overlay';
import { NoteSearchService } from './providers/note-search.service';
import { NoteImageService } from './providers/note-image.service';
import { MaterialModule } from '@shared/material.module';
import { DialogModule } from '@shared/dialog/dialog.module';
import { ToolbarModule } from '@shared/toolbar/toolbar.module';
import { VerticalToolbarModule } from '@shared/vertical-toolbar/vertical-toolbar.module';
import { ConfirmDialogModule } from '@shared/confirm-dialog/confirm-dialog.module';
import { NoSanitizePipe } from '@shared/pipes/no-sanitize.pipe';
import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { NotificationMessageModule } from '@shared/notification-message/notification-message.module';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		HttpClientModule,
		MatDialogModule,
		MatSelectModule,
		MatCardModule,
		CKEditorModule,
		PortalModule,
		OverlayModule,
		DialogModule,
		ToolbarModule,
		VerticalToolbarModule,
		ConfirmDialogModule,
		NotificationMessageModule,
		HomeRoutingModule,
		StoreModule.forFeature('noteBookCollection', reducers),
		EffectsModule.forFeature([NoteBookEffects, NoteEffects])
	],
	declarations: [
		HomeComponent,
		NotesSearchComponent,
		NoteBookComponent,
		AddNoteBookComponent,
		NoteListComponent,
		AddNoteItemComponent,
		NoteDetailsComponent,
		NoSanitizePipe
	],
	providers: [
		NoteBookService,
		NoteService,
		NoteBookPrefetchGuard,
		NoteItemPrefetchGuard,
		NoteSearchService,
		NoteImageService,
		NoSanitizePipe
	],
	entryComponents: [AddNoteBookComponent, ConfirmDialogComponent]
})
export class HomeModule { }
