import { Action } from '@ngrx/store';
import { Note } from '@shared/models/note.model';

export enum NoteActionTypes {
  ADD_NOTE = '[NOTE] ADD_NOTE',
  ADD_NOTE_SUCCESS = '[NOTE] ADD_NOTE_SUCCESS',
  ADD_NOTE_FAIL = '[NOTE] ADD_NOTE_FAIL',
  LOAD_NOTES_SUCCESS = '[NOTE] LOAD_NOTES_SUCCESS',
  NOTE_ADDED = '[NOTE] NOTE_ADDED',
  NOTE_SELECTED = '[NOTE] NOTE_SELECTED',
  NOTE_DELETED = '[NOTE] NOTE_DELETED',
  NOTE_UPDATED = '[NOTE] NOTE_UPDATED',
  DELETE_NOTE = '[NOTE] DELETE_NOTE',
  DELETE_NOTE_SUCCESS = '[NOTE] DELETE_NOTE_SUCCESS',
  DELETE_NOTE_FAIL = '[NOTE] DELETE_NOTE_FAIL',
  UPDATE_NOTE = '[NOTE] UPDATE_NOTE',
  UPDATE_NOTE_SUCCESS = '[NOTE] UPDATE_NOTE_SUCCESS',
  UPDATE_NOTE_FAIL = '[NOTE] UPDATE_NOTE_FAIL',
  EDIT_NOTE = '[NOTE] EDIT_NOTE'
}

export class AddNote implements Action {
  readonly type = NoteActionTypes.ADD_NOTE;

  constructor(public note: Note) {}
}

export class AddNoteSuccess implements Action {
  readonly type = NoteActionTypes.ADD_NOTE_SUCCESS;
}

export class AddNoteFail implements Action {
  readonly type = NoteActionTypes.ADD_NOTE_FAIL;

  constructor(public error: any) {}
}

export class LoadNotesSuccess implements Action {
  readonly type = NoteActionTypes.LOAD_NOTES_SUCCESS;

  constructor(public notes: Note[]) {}
}

export class NoteAdded implements Action {
  readonly type = NoteActionTypes.NOTE_ADDED;

  constructor(public note: Note) {}
}

export class NoteSelected implements Action {
  readonly type = NoteActionTypes.NOTE_SELECTED;

  constructor(public id: string) {}
}

export class NoteUpdated implements Action {
  readonly type = NoteActionTypes.NOTE_UPDATED;

  constructor(public note: Note) {}
}

export class DeleteNote implements Action {
  readonly type = NoteActionTypes.DELETE_NOTE;

  constructor(public id: string) {}
}

export class DeleteNoteSuccess implements Action {
  readonly type = NoteActionTypes.DELETE_NOTE_SUCCESS;
}

export class DeleteNoteFail implements Action {
  readonly type = NoteActionTypes.DELETE_NOTE_FAIL;

  constructor(public error: any) {}
}

export class NoteDeleted implements Action {
  readonly type = NoteActionTypes.NOTE_DELETED;

  constructor(public id: string) {}
}

export class UpdateNote implements Action {
  readonly type = NoteActionTypes.UPDATE_NOTE;

  constructor(public note: Note) {}
}

export class UpdateNoteSuccess implements Action {
  readonly type = NoteActionTypes.UPDATE_NOTE_SUCCESS;
}

export class UpdateNoteFail implements Action {
  readonly type = NoteActionTypes.UPDATE_NOTE_FAIL;

  constructor(public error: any) {}
}

export class EditNote implements Action {
  readonly type = NoteActionTypes.EDIT_NOTE;

  constructor(public note: Note) {}
}

export type NoteActions = AddNote
  | AddNoteSuccess
  | AddNoteFail
  | LoadNotesSuccess
  | NoteAdded
  | NoteSelected
  | NoteDeleted
  | NoteUpdated
  | DeleteNote
  | DeleteNoteSuccess
  | DeleteNoteFail
  | UpdateNote
  | UpdateNoteSuccess
  | UpdateNoteFail
  | EditNote;