import { from, Observable, throwError, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../store';
import * as firebase from 'firebase/app';
import 'firebase/database';
import * as fromNoteActions from '../store/actions/note.actions';
import { map, catchError, first, take, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/providers/auth.service';
import { NoteImageService } from './note-image.service';
import { Note } from '@shared/models/note.model';

@Injectable()
export class NoteService {
  constructor(
    private store: Store<fromStore.NoteCollectionState>,
    private authService: AuthService,
    private noteImageService: NoteImageService
  ) { }

  setUpDataLoadEvents(noteBookId: string): void {
    this.authService.onAuthentication().pipe(
      first()
    )
    .subscribe(() => {
      let initialDataLoaded = false;
      const userId = firebase.auth().currentUser.uid;
      const notesRef = firebase.database().ref('/users/' + userId + '/note-items/').orderByChild('noteBookId').equalTo(noteBookId);

      notesRef.on('child_added', (snapshot) => {
        if (initialDataLoaded) {
          const note = snapshot.val() as Note;
          this.store.dispatch(new fromNoteActions.NoteAdded(note));
        }
      });

      notesRef.on('child_removed', (snapshot) => {
        const note = snapshot.val() as Note;
        this.store.dispatch(new fromNoteActions.NoteDeleted(note.id));
      });

      notesRef.once('value').then((snapshot) => {
        initialDataLoaded = true;
        const noteData = snapshot.val() && Object.values(snapshot.val());
        const notes = noteData && noteData as Note[] || [];

        this.store.dispatch(new fromNoteActions.LoadNotesSuccess(notes));
      });
    });
  }

  addNote(note: Note): Observable<Note> {
    if (!note || note && !note.id) {
      throwError('no note provided to be added');
    }

    const userId = firebase.auth().currentUser.uid;
    const updates = {};
    const newNote = {
      ...note,
      search_name: note.name.toLowerCase()
    };
    updates['/users/' + userId + '/note-items/' + note.id] = newNote;

    return from(firebase.database().ref().update(updates).then((snapshot) => {
        return snapshot;
      })).pipe(
        take(1),
        map(() => newNote),
        catchError(error => throwError(error))
      );
  }

  deleteNote(noteId: string): Observable<void> {
    const userId = firebase.auth().currentUser.uid;
    const noteRef = firebase.database().ref('/users/' + userId + '/note-items/' + noteId);

    return from(noteRef.remove()).pipe(
        catchError(error => throwError(error))
      );
  }

  getNoteById(noteId: string): Observable<Note> {
    return this.authService.onAuthentication().pipe(
      first(),
      switchMap(() => {
        const userId = firebase.auth().currentUser.uid;
        const notesRef = firebase.database().ref('/users/' + userId + '/note-items/' + noteId);

        return from(notesRef.once('value').then((snapshot) => {
          const noteData = snapshot.val();
          const note = noteData && noteData as Note;

          return note;
        }))
        .pipe(first());
      })
    );
  }

  updateNote(note: Note): Observable<Note> {
    const userId = firebase.auth().currentUser.uid;

    // update images related to this note
    return this.noteImageService
      .updateImageLinksForNoteBook(note)
      .pipe(
        take(1),
        switchMap(() => {
          const updates = {};
          updates['/users/' + userId + '/note-items/' + note.id] = note;

          return from(firebase.database().ref().update(updates).then((snapshot) => {
              return snapshot;
            })).pipe(
              take(1),
              map(() => note),
              catchError(error => throwError(error))
            );
        })
      );
  }

  getAllNotesbyNoteBookId(noteBookId: string): Observable<Note[]> {
    const userId = firebase.auth().currentUser.uid;
    const notesRef = firebase.database().ref('/users/' + userId + '/note-items/');

    return from(
      notesRef
        .orderByChild('noteBookId')
        .equalTo(noteBookId)
        .once('value')
        .then((snapshot) => {
          const noteData = snapshot.val() && Object.values(snapshot.val());
          const notes = noteData && noteData as Note[] || [];

          return notes;
        })
      );
  }

  deleteNoteList(notes: Note[]): Observable<void> {
    notes = notes || [];
    const result: Subject<void> = new Subject<void>();
    const allDeletionTasks: Promise<any>[] = [];

    notes.forEach(n => {
      const deletionTask: Promise<void> = this.deleteNote(n.id).toPromise();
      allDeletionTasks.push(deletionTask);
    });

    Promise.all(allDeletionTasks).then(() => result.next());

    return result.asObservable();
  }
}
