import * as firebase from 'firebase/app';
import 'firebase/auth';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { switchMap, tap, filter } from 'rxjs/operators';
import { LoginData } from '@shared/models/login.model';

export class AuthService {
  constructor() {
    firebase.initializeApp({
      apiKey: 'AIzaSyDpHugAqWdmYTAVo6--JnFDNYWqMtY1o34',
      authDomain: 'notes-app-edf44.firebaseapp.com',
      databaseURL: 'https://notes-app-edf44.firebaseio.com',
      storageBucket: 'gs://notes-app-edf44.appspot.com'
    });

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        user.getIdToken().then(token => this.setToken(token));
        this.isAuthenticationFinished$.next(true);
      }
      else {
        this.isAuthenticationFailed$.next();
      }
    });
  }

  private token: string;
  private isAuthenticationFinished$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isAuthenticationFailed$: Subject<void> = new Subject<void>();

  login(loginData: LoginData) {
    // return from(firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL))
    return from(firebase.auth().signInWithEmailAndPassword(loginData.email, loginData.password))
      .pipe(
        // switchMap(() => from(firebase.auth().signInWithEmailAndPassword(loginData.email, loginData.password))),
        switchMap(() => from(firebase.auth().currentUser.getIdToken())),
        tap(token => this.setToken(token))
      );
  }

  getToken(): string {
    return this.token;
  }

  setToken(token: string) {
    this.token = token;
  }

  isAuthenticated(): boolean {
    return this.isAuthenticationFinished$.getValue();
  }

  /**
   * Returns an Observable that fires only when the user authentcation finishes successfully
   * otherwise it never fires
   */
  onAuthentication(): Observable<boolean> {
    return this.isAuthenticationFinished$.asObservable()
      .pipe(filter(isLoggedIn => isLoggedIn));
  }

  onAuthenticationFailed(): Observable<void> {
    return this.isAuthenticationFailed$.asObservable();
  }
}
