import { Component, Input } from '@angular/core';
import { DialogButton } from '../models/dialog-button.model';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
    @Input()
    title = 'Dialog';

    @Input()
    actionButtons: DialogButton[] = [];
}
