import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, switchMap, catchError } from 'rxjs/operators';
import * as fromNoteBookActions from '../actions/note-book.actions';
import { NoteBookService } from '../../providers/note-book.service';
import { of } from 'rxjs';
import { NoteService } from '../../providers/note.service';
import { NoteImageService } from '../../providers/note-image.service';
import { NotificationMessageService } from '@shared/notification-message/notification-message.service';

@Injectable()
export class NoteBookEffects {
  @Effect()
  addNoteBook$ = this.actions$.pipe(
    ofType(fromNoteBookActions.NoteBookActionTypes.ADD_NOTEBOOK),
    map((action: fromNoteBookActions.AddNoteBook) => action.notebook),
    switchMap(noteBook => this.noteBookService.addNoteBook(noteBook.name)),
    map(() => {
      this.notificationMessageService.showSuccess('Notebook Created!');
      return new fromNoteBookActions.AddNoteBookSuccess()
    }),
    catchError(error => {
      this.notificationMessageService.showError('Notebook could not be created!');
      return of(new fromNoteBookActions.AddNoteBookFail(error));
    })
  );

  @Effect()
  deleteNotebook$ = this.actions$.pipe(
    ofType(fromNoteBookActions.NoteBookActionTypes.DELETE_NOTEBOOK),
    map((action: fromNoteBookActions.DeleteNoteBook) => action.id),
    switchMap(id => this.noteService.getAllNotesbyNoteBookId(id)
      .pipe(
        map(notes => {
          return { id, notes };
        })
      )
    ),
    switchMap(({ id, notes }) => this.noteImageService.deleteAllImagesForNoteList(notes)
      .pipe(
        map(() => {
          return { id, notes };
        })
      )
    ),
    switchMap(({ id, notes }) => this.noteService.deleteNoteList(notes)
      .pipe(
        map(() => {
          return { id, notes };
        })
      )
    ),
    switchMap(({ id, notes }) => this.noteBookService.deleteNoteBook(id)),
    map(() => {
      this.notificationMessageService.showSuccess('Notebook Removed!');
      return new fromNoteBookActions.DeleteNoteBookSuccess()
    }),
    catchError(error => {
      this.notificationMessageService.showError('Notebook could not be removed!');
      return of(new fromNoteBookActions.DeleteNoteBookFail(error));
    })
  );

  constructor(
    private actions$: Actions,
    private noteBookService: NoteBookService,
    private noteService: NoteService,
    private noteImageService: NoteImageService,
    private notificationMessageService: NotificationMessageService
  ) {
    console.log('NoteBook efect initialized');
  }
}
