import { NoteBookService } from '../providers/note-book.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NoteBookPrefetchGuard implements CanActivate {
  constructor(private noteBookService: NoteBookService) {}

  canActivate(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Observable<boolean> {
    if (routerState.url === '/notebook' || routerState.url === '/add-note') {
      this.noteBookService.setUpDataLoadEvents();
      return true;
    }
    else {
      const noteBookId = route.params['notebookId'];

      return this.noteBookService.getNoteBookById(noteBookId)
      .pipe(
        map(noteBook => {
          if (noteBook) {
            route.data = { noteBook };
          }
          return !!noteBook;
        })
      );
    }
  }
}