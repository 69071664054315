import { from, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromStore from '../store';
import * as firebase from 'firebase/app';
import 'firebase/database';
import * as fromNoteBookActions from '../store/actions/note-book.actions';
import { map, catchError, first, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/providers/auth.service';
import { NoteBook } from '@shared/models/note-book.model';

@Injectable()
export class NoteBookService {
  constructor(private store: Store<fromStore.NoteCollectionState>, private authService: AuthService) { }

  setUpDataLoadEvents(): void {
    this.authService.onAuthentication().pipe(
      first()
    )
    .subscribe(() => {
      let initialDataLoaded = false;
      const userId = firebase.auth().currentUser.uid;
      const notesRef = firebase.database().ref('/users/' + userId + '/noteBooks/');

      notesRef.on('child_added', (snapshot) => {
        if (initialDataLoaded) {
          const noteBook = snapshot.val() as NoteBook;
          this.store.dispatch(new fromNoteBookActions.NoteBookAdded(noteBook));
        }
      });

      notesRef.on('child_removed', (snapshot) => {
        const noteBook = snapshot.val() as NoteBook;
        this.store.dispatch(new fromNoteBookActions.NoteBookDeleted(noteBook.id));
      });

      notesRef.once('value').then((snapshot) => {
        initialDataLoaded = true;
        const notebookData = snapshot.val() && Object.values(snapshot.val());
        const noteBooks = notebookData && notebookData as NoteBook[] || [];

        this.store.dispatch(new fromNoteBookActions.LoadNoteBooksSuccess(noteBooks));
      });
    });
  }

  saveNoteBooks() {

    const userId = firebase.auth().currentUser.uid;
    const notes = ['Health', 'Personal', 'Food', 'Motivation'];
    notes.forEach(n => {
      // Get a key for a new Post.
      const newNoteKey = firebase.database().ref().child('noteBooks').push().key;
      // Write the new post's data simultaneously in the posts list and the user's post list.
      const updates = {};
      updates['/users/' + userId + '/noteBooks/' + newNoteKey] = {
        id: newNoteKey,
        name: n
      };

      firebase.database().ref().update(updates);
    });
  }

  addNoteBook(name: string): Observable<boolean> {
    const userId = firebase.auth().currentUser.uid;
    // Get a key for a new Post.
    const newNoteKey = firebase.database().ref().child('noteBooks').push().key;
    // Write the new post's data simultaneously in the posts list and the user's post list.
    const updates = {};
    updates['/users/' + userId + '/noteBooks/' + newNoteKey] = {
      id: newNoteKey,
      name,
      search_name: name.toLowerCase()
    };

    return from(firebase.database().ref().update(updates).then((snapshot) => {
        return snapshot;
      })).pipe(
        map(noteBookData => !!noteBookData),
        catchError(error => throwError(error))
      );
  }

  deleteNoteBook(noteBookId: string): Observable<void> {
    const userId = firebase.auth().currentUser.uid;

    const noteBookRef = firebase.database().ref('/users/' + userId + '/noteBooks/' + noteBookId);

    return from(noteBookRef.remove()).pipe(
        catchError(error => throwError(error))
      );
  }

  getNoteBookById(noteBookId: string): Observable<NoteBook> {
    return this.authService.onAuthentication().pipe(
      first(),
      switchMap(() => {
        const userId = firebase.auth().currentUser.uid;
        const noteBookRef = firebase.database().ref('/users/' + userId + '/noteBooks/' + noteBookId);

        return from(noteBookRef.once('value').then((snapshot) => {
          const noteBookData = snapshot.val();
          const note = noteBookData && noteBookData as NoteBook;

          return note;
        }))
        .pipe(first());
      })
    );
  }
}
