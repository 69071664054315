import { Component, OnDestroy, OnInit } from '@angular/core';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import * as fromNoteBookSelectors from '../../store/selectors/note-book.selectors';
import * as fromNoteActions from '../../store/actions/note.actions';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map, startWith } from 'rxjs/operators';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NoteImageService } from '../../providers/note-image.service';
import * as firebase from 'firebase/app';
import 'firebase/database';
import { BreadCrumbItem, ToolbarButton } from '@shared/toolbar/toolbar.component';
import { NoteBook } from '@shared/models/note-book.model';
import { Note } from '@shared/models/note.model';
import { FirebaseFileUploadAdapterPlugin } from '@shared/firebase-file-upload-adaptor/firebase-file-upload-adaptor';

@Component({
  selector: 'app-add-note-item',
  styleUrls: ['./add-note-item.component.scss'],
  templateUrl: './add-note-item.component.html'
})
export class AddNoteItemComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<fromStore.NoteCollectionState>,
    private fb: FormBuilder,
    private router: Router,
    private location: Location,
    private breakPoint: BreakpointObserver,
    private noteImageService: NoteImageService
  ) {}

  editor = ClassicEditor;
  editorContent = '';
  private onDestroy$: Subject<void> = new Subject<void>();
  isSmallScreen = this.breakPoint.isMatched('(max-width: 450px)');
  noteId: string;
  ckconfig = {
    // include any other configuration you want
    extraPlugins: [ FirebaseFileUploadAdapterPlugin ]
  };
  
  noteForm = this.fb.group({
    name: ['', Validators.required],
    noteBookId: ['', Validators.required]
  });

  breadcrumbs: BreadCrumbItem[] = [
    {
      text: 'New Note'
    }
  ];

  private homeButton: ToolbarButton = {
    text: 'Home',
    icon: 'home',
    action: () => this.router.navigateByUrl("/notebook")
  };

  private backButton: ToolbarButton = {
    text: 'Back',
    icon: 'arrow_back',
    action: () => this.location.back()
  };

  private saveButton: ToolbarButton = {
    text: "Save",
    icon: "save",
    disabled$: this.noteForm.statusChanges.pipe(
      startWith("INVALID"),
      map(status => status === "INVALID"),
      takeUntil(this.onDestroy$)
    ),
    action: () => this.saveNote()
  };

  private deleteButton: ToolbarButton = {
    text: "Reset",
    icon: "refresh",
    action: () => this.resetForm()
  };

  toolbarButtons: ToolbarButton[] = [
    this.backButton,
    this.homeButton,
    this.saveButton,
    this.deleteButton
  ];

  availableNoteBooks$: Observable<NoteBook[]> = this.store.select(fromNoteBookSelectors.getNoteBooksAsArray)
    .pipe(
      takeUntil(this.onDestroy$)
    );

  ngOnInit() {
    this.noteId = firebase.database().ref().child('note-items').push().key;
    this.noteImageService.openedNoteInfo(null, this.noteId);
  }

  saveNote() {
    if (this.noteForm.valid) {
      const note: Note = {
        id: this.noteId,
        name: this.noteForm.controls.name.value,
        noteBookId: this.noteForm.controls.noteBookId.value,
        content: this.editorContent
      };

      this.store.dispatch(new fromNoteActions.AddNote(note));
    }
  }

  resetForm() {
    this.noteForm.reset();
    this.editorContent = '';
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
