import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NoteBookComponent } from './components/note-book/note-book.component';
import { HomeComponent } from './containers/home/home.component';
import { NoteListComponent } from './containers/note-list/note-list.component';
import { AddNoteItemComponent } from './containers/add-note-item/add-note-item.component';
import { NoteBookPrefetchGuard } from './guards/note-book-prefetch.guard';
import { NoteDetailsComponent } from './containers/note-details/note-details.component';
import { NoteItemPrefetchGuard } from './guards/note-prefetch.guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'notebook'
      },
      {
        path: 'notebook',
        component: NoteBookComponent,
        canActivate: [NoteBookPrefetchGuard]
      },
      {
        path: 'notebook/:notebookId',
        component: NoteListComponent,
        canActivate: [NoteBookPrefetchGuard]
      },
      {
        path: 'note/:noteId',
        component: NoteDetailsComponent,
        canActivate: [NoteItemPrefetchGuard]
      }
    ]
  },
  {
    path: 'add-note',
    component: AddNoteItemComponent,
    canActivate: [NoteBookPrefetchGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }