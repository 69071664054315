import { NoteBookState, NoteBookReducer } from './reducers/note-book.reducer';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { NoteState, NoteReducer } from './reducers/note.reducer';

export interface NoteCollectionState {
  noteBooks: NoteBookState;
  notes: NoteState
}

export const reducers: ActionReducerMap<NoteCollectionState> = {
  noteBooks: NoteBookReducer,
  notes: NoteReducer
};

export const noteCollectionFeature = createFeatureSelector('noteBookCollection');