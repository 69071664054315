import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import * as fromNoteBookActions from '../../store/actions/note-book.actions';
import { DialogButton } from '@shared/models/dialog-button.model';

@Component({
    selector: 'app-add-note-book',
    templateUrl: './add-note-book.component.html',
    styleUrls: ['./add-note-book.component.scss']
})
export class AddNoteBookComponent implements OnInit {
    constructor(
        private dialogref: MatDialogRef<AddNoteBookComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private store: Store<fromStore.NoteCollectionState>
    ) { }

    name: FormControl;
    hasName$ = new BehaviorSubject<boolean>(false);

    private cancelButton: DialogButton = {
        text: 'Cancel',
        action: () => this.dialogref.close()
    };

    private addButton: DialogButton;

    actionButtons: DialogButton[];

    ngOnInit() {
        this.name = new FormControl('', Validators.required);

        this.addButton = {
            text: 'Add',
            isPrimary: true,
            disabled$: this.hasName$,
            action: () => this.confirmAdd()
        };

        this.actionButtons = [this.cancelButton, this.addButton];

        this.name.valueChanges.pipe(map(val => !val))
            .subscribe(isValid => this.hasName$.next(isValid));

        // To disable the add button
        this.name.setValue('');
    }

    confirmAdd() {
        if (this.name.valid) {
            this.store.dispatch(new fromNoteBookActions.AddNoteBook({
                name: this.name.value,
                id: undefined
            }));
            this.dialogref.close();
        }
    }
}
