import { AuthService } from './providers/auth.service';
import { NgModule } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './authentication/login/login.component';
import { CoreRoutingModule } from './core.routing.module';
import { RegisterComponent } from './authentication/register/register.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@shared/material.module';

@NgModule({
    declarations: [
        HeaderComponent,
        LoginComponent,
        RegisterComponent
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        FormsModule,
        MaterialModule,
        CoreRoutingModule
    ],
    exports: [HeaderComponent],
    providers: [AuthService]
})
export class CoreModule {

}
