import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromAuthActions from './store/actions/auth.actions';
import * as fromAppStore from './store';
import { AuthService } from './core/providers/auth.service';
import { Router } from '@angular/router';
import { GlobalEventService } from './providers/global-event.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'notes-app';

  constructor(
    private store: Store<fromAppStore.AppState>,
    private authService: AuthService,
    private router: Router,
    private globalEventService: GlobalEventService /** this service is needed globally */
  ) { }

  ngOnInit() {
    this.authService.onAuthentication().subscribe(() => {
      this.store.dispatch(new fromAuthActions.SetExistingToken(this.authService.getToken()));
    });

    this.authService.onAuthenticationFailed().subscribe(() => {
      this.router.navigateByUrl('login');
    });
  }
}
