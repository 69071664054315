import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { NoteService } from '../../providers/note.service';
import * as fromNoteActions from '../../store/actions/note.actions';
import { map, switchMap, catchError, first } from 'rxjs/operators';
import { of } from 'rxjs';
import { Router } from '@angular/router';
import { NoteImageService } from '../../providers/note-image.service';
import * as fromStore from '../../store';
import * as fromNoteSelectors from '../../store/selectors/note.selectors';
import { Store } from '@ngrx/store';
import { NotificationMessageService } from '@shared/notification-message/notification-message.service';

@Injectable()
export class NoteEffects {
  @Effect()
  addNote$ = this.actions$.pipe(
    ofType(fromNoteActions.NoteActionTypes.ADD_NOTE),
    map((action: fromNoteActions.AddNote) => action.note),
    switchMap(note => this.noteImageService.updateImageLinksForNoteBook(note).pipe(map(() => note))),
    switchMap(note => this.notesService.addNote(note)),
    map(note => {
      this.notificationMessageService.showSuccess('Note Created!');
      this.router.navigateByUrl(`/notebook/${note.noteBookId}`);
      return new fromNoteActions.AddNoteSuccess()
    }),
    catchError(error => {
      this.notificationMessageService.showError('Note could not be created!');
      return of(new fromNoteActions.AddNoteFail(error));
    })
  );

  @Effect()
  deleteNote$ = this.actions$.pipe(
    ofType(fromNoteActions.NoteActionTypes.DELETE_NOTE),
    map((action: fromNoteActions.DeleteNote) => action.id),
    switchMap(id => this.store.select(fromNoteSelectors.getSelectedNote).pipe(first())),
    switchMap(note => {
      return this.noteImageService.deleteAllImagesForNote(note.id)
        .pipe(map(() => note.id));
    }),
    switchMap(id => this.notesService.deleteNote(id)),
    map(() => {
      this.notificationMessageService.showSuccess('Note removed!');
      return new fromNoteActions.DeleteNoteSuccess()
    }),
    catchError(error => {
      this.notificationMessageService.showError('Note could not be removed!');
      return of(new fromNoteActions.DeleteNoteFail(error));
    })
  );

  @Effect()
  updateNote$ = this.actions$.pipe(
    ofType(fromNoteActions.NoteActionTypes.UPDATE_NOTE),
    map((action: fromNoteActions.UpdateNote) => action.note),
    switchMap(note => this.notesService.updateNote(note)),
    map(() => {
      this.notificationMessageService.showSuccess('Note Saved!');
      return new fromNoteActions.UpdateNoteSuccess()
    }),
    catchError(error => {
      this.notificationMessageService.showError('Note could not be saved!');
      return of(new fromNoteActions.UpdateNoteFail(error));
    })
  );

  constructor(
    private actions$: Actions,
    private notesService: NoteService,
    private noteImageService: NoteImageService,
    private router: Router,
    private store: Store<fromStore.NoteCollectionState>,
    private notificationMessageService: NotificationMessageService
  ) {
    console.log('Note efect initialized');
  }
}