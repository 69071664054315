import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationMessageComponent } from './notification-message.component';
import { NotificationMessageService } from './notification-message.service';
import { MatFormFieldModule, MatSnackBarModule, MatInputModule, MatIconModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatInputModule,
    MatIconModule
  ],
  declarations: [NotificationMessageComponent],
  exports: [NotificationMessageComponent],
  entryComponents: [NotificationMessageComponent],
  providers: [NotificationMessageService]
})
export class NotificationMessageModule {}
