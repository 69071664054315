import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import * as fromNoteBookSelectors from '../../store/selectors/note-book.selectors';
import * as fromNoteBookActions from '../../store/actions/note-book.actions';
import { take, map, switchMap, first, takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { AddNoteBookComponent } from '../add-note-book/add-note-book.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { VerticalToolbar } from '@shared/vertical-toolbar/providers/vertical-toolbar';
import { NoteBook } from '@shared/models/note-book.model';
import { ToolbarButton, BreadCrumbItem } from '@shared/toolbar/toolbar.component';
import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { VerticalToolbarComponent } from '@shared/vertical-toolbar/vertical-toolbar.component';

@Component({
  selector: 'app-note-book',
  styleUrls: ['./note-book.component.scss'],
  templateUrl: './note-book.component.html'
})
export class NoteBookComponent implements OnInit, OnDestroy {
  constructor(
    private dialog: MatDialog,
    private store: Store<fromStore.NoteCollectionState>,
    private router: Router,
    private breakPoint: BreakpointObserver,
    private verticalToolbar: VerticalToolbar
  ) { }

  noteBooks$: Observable<NoteBook[]>;
  selectedNoteBookId$: Observable<string>;
  numOfColumns: number;
  gutterSize: number;
  rowHeight: string;
  isSmallScreen = this.breakPoint.isMatched('(max-width: 450px)');

  private doubleClickTimer;
	private onDestroy$: Subject<void> = new Subject<void>();
  private isSelectedNoteBook$: Observable<boolean> =
    this.store.select(fromNoteBookSelectors.getSelectedNoteBookId)
      .pipe(map(n => !!n));

  deleteBtn: ToolbarButton = {
    text: 'Delete',
    icon: 'delete',
    hidden$: this.isSelectedNoteBook$.pipe(map(n => !n)),
    action: () => this.deleteNoteBook()
  };

  opnBtn: ToolbarButton = {
    text: 'Open',
    icon: 'folder_open',
    hidden$: this.isSelectedNoteBook$.pipe(map(n => !n)),
    action: () => this.openNoteBook()
  };

  toolbarButtons: ToolbarButton[] = [
    this.opnBtn,
    this.deleteBtn
  ];

  breadcrumbs: BreadCrumbItem[] = [
    {
      text: "NoteBooks"
    }
  ];

  ngOnInit() {
    this.noteBooks$ = this.store.select(fromNoteBookSelectors.getNoteBooksAsArray);
    this.selectedNoteBookId$ = this.store.select(fromNoteBookSelectors.getSelectedNoteBookId);

    this.calculateNumberOfColumns();
  }

  itemClicked(id: string) {
    if (this.doubleClickTimer || this.isSmallScreen) {
      // double click
      clearTimeout(this.doubleClickTimer);
      this.doubleClickTimer = null;
      this.noteBookSelected(id);
      this.openNoteBook();
    }
    else {
      // single click
      this.noteBookSelected(id);
      this.doubleClickTimer = setTimeout(() => {
        this.doubleClickTimer = null;
      }, 250);
    }
  }

  noteBookSelected(id: string) {
    if (id) {
      this.store.select(fromNoteBookSelectors.getSelectedNoteBookId)
        .pipe(take(1))
        .subscribe(selectedNoteBookId => {
          if (selectedNoteBookId !== id) {
            this.store.dispatch(new fromNoteBookActions.NoteBookSelected(id));
          }
        });
    }
  }

  deleteNoteBook() {
		var dialogRef = this.dialog.open(ConfirmDialogComponent, {
			width: '350px',
			height: '174px',
			data: {
				message: "Delete this notebook?"
			}
		});

		dialogRef.componentInstance.onConfirm()
			.pipe(
				switchMap(() => this.store.select(fromNoteBookSelectors.getSelectedNoteBookId)),
				first(),
				takeUntil(this.onDestroy$)
			)
			.subscribe(noteBookId => {
				if (noteBookId) {
					this.store.dispatch(new fromNoteBookActions.DeleteNoteBook(noteBookId));
				}
			});
  }

  openNoteBook() {
    this.store.select(fromNoteBookSelectors.getSelectedNoteBookId)
      .pipe(first())
      .subscribe(noteBookId => {
        if (noteBookId) {
          this.router.navigateByUrl("/notebook/" + noteBookId);
        }
      });
  }

  addNotebook() {
		this.dialog.open(AddNoteBookComponent, {
			width: '500px',
			height: '220px'
		});
  }

  openVerticalToolbar(event: Event, noteBookId: string) {
    event.stopPropagation();

    this.store.dispatch(new fromNoteBookActions.NoteBookSelected(noteBookId));

    this.verticalToolbar.open(VerticalToolbarComponent, {
      buttons: [
        {
          icon: 'folder_open',
          action: () => this.openNoteBook()
        },
        {
          icon: 'delete',
          action: () => this.deleteNoteBook()
        }
      ]
    });
  }

  calculateNumberOfColumns() {
    if (window.innerWidth < 450) {
      this.numOfColumns = 1;
      this.gutterSize = 5;
      this.rowHeight = '6:1';
    }
    else {
      this.numOfColumns = 3;
      this.gutterSize = 16;
      this.rowHeight = '4:1';
    }
  }

  onResize() {
    this.calculateNumberOfColumns();
  }
  
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
