import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/core/providers/auth.service';
import { first, switchMap, map, filter } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/database';
import { Observable, combineLatest, from } from 'rxjs';
import { NoteSearch, SearchItemType } from '@shared/models/note-search.model';
import { NoteBook } from '@shared/models/note-book.model';
import { Note } from '@shared/models/note.model';

@Injectable()
export class NoteSearchService {
  constructor(private authService: AuthService) {}

  search(value: string): Observable<NoteSearch[]> {
    return this.authService.onAuthentication().pipe(
      first(),
      //filter(() => value && value.length > 2),
      map(() => firebase.auth().currentUser.uid),
      switchMap(uid => combineLatest(
        this.fetchNoteBooks(uid, value),
        this.fetchNotes(uid, value)
      ).pipe(first())),
      map(([noteBooks, notes]) => {
        const searchItems: NoteSearch[] = [];

        if (noteBooks && noteBooks.length) {
          noteBooks.forEach(n => searchItems.push(
            {
              name: n.name,
              type: SearchItemType.NoteBook,
              icon: 'library_books',
              itemId: n.id,
              url: `/notebook/${n.id}`
            }
          ));
        }

        if (notes && notes.length) {
          notes.forEach(n => searchItems.push(
            {
              name: n.name,
              type: SearchItemType.Note,
              icon: 'notes',
              itemId: n.id,
              url: `note/${n.id}`
            }
          ));
        }

        return searchItems;
      })
    );
  }

  private fetchNoteBooks(userId: string, name: string): Observable<NoteBook[]> {
    const noteBookRef = firebase.database().ref('/users/' + userId + '/noteBooks/');

    return from(
      noteBookRef
        .orderByChild('search_name')
        .startAt(name.toLowerCase())
        .endAt(name.toLowerCase() + '\uf8ff')
        .limitToFirst(10)
        .once('value')
        .then((snapshot) => {
          const noteBookData = snapshot.val() && Object.values(snapshot.val());
          const noteBooks = noteBookData && noteBookData as NoteBook[] || [];

          return noteBooks;
        })
      );
  }

  private fetchNotes(userId: string, name: string): Observable<Note[]> {
    const notesRef = firebase.database().ref('/users/' + userId + '/note-items/');

    return from(
      notesRef
        .orderByChild('search_name')
        .startAt(name.toLowerCase())
        .endAt(name.toLowerCase() + '\uf8ff')
        .limitToFirst(10)
        .once('value')
        .then((snapshot) => {
          const noteData = snapshot.val() && Object.values(snapshot.val());
          const notes = noteData && noteData as Note[] || [];

          return notes;
        })
      );
  }
}