import { Action } from '@ngrx/store';
import { NoteBook } from '@shared/models/note-book.model';

export enum NoteBookActionTypes {
  ADD_NOTEBOOK = '[NOTEBOOK] ADD_NOTEBOOK',
  ADD_NOTEBOOK_SUCCESS = '[NOTEBOOK] ADD_NOTEBOOK_SUCCESS',
  ADD_NOTEBOOK_FAIL = '[NOTEBOOK] ADD_NOTEBOOK_FAIL',
  LOAD_NOTEBOOKS_SUCCESS = '[NOTEBOOK] LOAD_NOTEBOOKS_SUCCESS',
  NOTEBOOK_ADDED = '[NOTEBOOK] NOTEBOOK_ADDED',
  NOTEBOOK_SELECTED = '[NOTEBOOK] NOTEBOOK_SELECTED',
  NOTEBOOK_DELETED = '[NOTEBOOK] NOTEBOOK_DELETED',
  DELETE_NOTEBOOK = '[NOTEBOOK] DELETE_NOTEBOOK',
  DELETE_NOTEBOOK_SUCCESS = '[NOTEBOOK] DELETE_NOTEBOOK_SUCCESS',
  DELETE_NOTEBOOK_FAIL = '[NOTEBOOK] DELETE_NOTEBOOK_FAIL',
}

export class AddNoteBook implements Action {
  readonly type = NoteBookActionTypes.ADD_NOTEBOOK;

  constructor(public notebook: NoteBook) {}
}

export class AddNoteBookSuccess implements Action {
  readonly type = NoteBookActionTypes.ADD_NOTEBOOK_SUCCESS;
}

export class AddNoteBookFail implements Action {
  readonly type = NoteBookActionTypes.ADD_NOTEBOOK_FAIL;

  constructor(public error: any) {}
}

export class LoadNoteBooksSuccess implements Action {
  readonly type = NoteBookActionTypes.LOAD_NOTEBOOKS_SUCCESS;

  constructor(public notebooks: NoteBook[]) {}
}

export class NoteBookAdded implements Action {
  readonly type = NoteBookActionTypes.NOTEBOOK_ADDED;

  constructor(public notebook: NoteBook) {}
}

export class NoteBookSelected implements Action {
  readonly type = NoteBookActionTypes.NOTEBOOK_SELECTED;

  constructor(public id: string) {}
}

export class DeleteNoteBook implements Action {
  readonly type = NoteBookActionTypes.DELETE_NOTEBOOK;

  constructor(public id: string) {}
}

export class DeleteNoteBookSuccess implements Action {
  readonly type = NoteBookActionTypes.DELETE_NOTEBOOK_SUCCESS;
}

export class DeleteNoteBookFail implements Action {
  readonly type = NoteBookActionTypes.DELETE_NOTEBOOK_FAIL;

  constructor(public error: any) {}
}

export class NoteBookDeleted implements Action {
  readonly type = NoteBookActionTypes.NOTEBOOK_DELETED;

  constructor(public id: string) {}
}

export type NoteBookActions = AddNoteBook
  | AddNoteBookSuccess
  | AddNoteBookFail
  | LoadNoteBooksSuccess
  | NoteBookAdded
  | NoteBookSelected
  | NoteBookDeleted
  | DeleteNoteBook
  | DeleteNoteBookSuccess
  | DeleteNoteBookFail;