import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerticalToolbarComponent } from './vertical-toolbar.component';
import { MatCardModule, MatListModule, MatIconModule } from '@angular/material';
import { VerticalToolbar } from './providers/vertical-toolbar';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatListModule,
    MatIconModule
  ],
  declarations: [VerticalToolbarComponent],
  exports: [VerticalToolbarComponent],
  entryComponents: [VerticalToolbarComponent],
  providers: [VerticalToolbar]
})
export class VerticalToolbarModule {}
