import { LoginActions, LoginActionTypes } from '../actions/auth.actions';

export interface AuthState {
  isAuthenticated: boolean;
  token: string;
}

export const initialState: AuthState = {
  isAuthenticated: false,
  token: null
};

export function AuthReducer(state = initialState, action: LoginActions) {
  switch (action.type) {
    case LoginActionTypes.REGISTER:
    case LoginActionTypes.LOGIN: {
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload
      };
    }

    case LoginActionTypes.LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
        token: null
      };
    }
  }

  return state;
}
