export enum SearchItemType {
  Note = 'Note',
  NoteBook = 'NoteBook'
}

export class NoteSearch {
  name: string;
  type: SearchItemType;
  icon: string;
  itemId: string;
  url: string;
}