import { Component, OnDestroy } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import * as fromStore from '../../store';
import * as fromNoteSelectors from '../../store/selectors/note.selectors';
import * as fromNoteActions from '../../store/actions/note.actions';
import { ActivatedRoute, Router } from '@angular/router';
import { map, takeUntil, tap, first, withLatestFrom, take } from 'rxjs/operators';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NoteImageService } from '../../providers/note-image.service';
import { VerticalToolbar } from '@shared/vertical-toolbar/providers/vertical-toolbar';
import { Note } from '@shared/models/note.model';
import { BreadCrumbItem, ToolbarButton } from '@shared/toolbar/toolbar.component';
import { VerticalToolbarComponent } from '@shared/vertical-toolbar/vertical-toolbar.component';
import { FirebaseFileUploadAdapterPlugin } from '@shared/firebase-file-upload-adaptor/firebase-file-upload-adaptor';

@Component({
  selector: 'app-note-details',
  styleUrls: ['./note-details.component.scss'],
  templateUrl: './note-details.component.html'
})
export class NoteDetailsComponent implements OnDestroy {
  constructor(
    private store: Store<fromStore.NoteCollectionState>,
    private route: ActivatedRoute,
    private router: Router,
    private breakPoint: BreakpointObserver,
    private verticalToolbar: VerticalToolbar,
    private noteImageService: NoteImageService
  ) {}

  private onDestroy$: Subject<void> = new Subject<void>();
  note$: Observable<Note> = this.store.select(fromNoteSelectors.getEditNote)
    .pipe(takeUntil(this.onDestroy$));
  editor = ClassicEditor;
  editorContent = '';
  isEditNote$ = new BehaviorSubject<boolean>(false);
  isSmallScreen = this.breakPoint.isMatched('(max-width: 450px)');
  ckconfig = {
    // include any other configuration you want
    extraPlugins: [ FirebaseFileUploadAdapterPlugin ]
  };
  
  breadcrumbs: BreadCrumbItem[] = [
    {
      text: 'NoteBooks',
      link: '/notebook'
    }
  ];

  private editButton: ToolbarButton = {
    text: 'Edit',
    icon: 'edit',
    hidden$: this.isEditNote$
      .pipe(
        takeUntil(this.onDestroy$)
    ),
    action: () => this.editNote()
  };

  private cancelButton: ToolbarButton = {
    text: 'Cancel',
    icon: 'cancel',
    hidden$: this.isEditNote$
      .pipe(
        map(isEditMode => !isEditMode),
        takeUntil(this.onDestroy$)
    ),
    action: () => this.cancelEditNote()
  };

  private saveButton: ToolbarButton = {
    text: 'Save',
    icon: 'save',
    hidden$: this.isEditNote$
      .pipe(
        map(isEditMode => !isEditMode),
        takeUntil(this.onDestroy$)
    ),
    action: () => this.saveNote()
  };

  private deleteButton: ToolbarButton = {
    text: 'Delete',
    icon: 'delete',
    action: () => this.deleteNote()
  };

  private moreOptionsButton: ToolbarButton = {
    text: 'Options',
    icon: 'more_vert',
    action: () => this.openVerticalToolbar()
  };

  toolbarButtons: ToolbarButton[] = [];

  ngOnInit() {
    this.route.data.pipe(
      withLatestFrom(this.note$),
      tap(([data, note]) => {
        this.breadcrumbs[1] = {
          text: data.noteBook.name,
          link: `/notebook/${data.noteBook.id}`
        };
        this.breadcrumbs[2] = {
          text: note.name
        };
        this.noteImageService.openedNoteInfo(data.noteBook.id, note.id);
      }),
      takeUntil(this.onDestroy$)
    ).subscribe();

    this.toolbarButtons = this.isSmallScreen ? [this.moreOptionsButton] : [
      this.editButton,
      this.cancelButton,
      this.saveButton,
      this.deleteButton
    ];
  }

  openVerticalToolbar() {
    this.verticalToolbar.open(VerticalToolbarComponent, {
      buttons: [
        {
          icon: 'edit',
          hidden$: this.isEditNote$
            .pipe(
              takeUntil(this.onDestroy$)
          ),
          action: () => this.editNote()
        },
        {
          icon: 'cancel',
          hidden$: this.isEditNote$
            .pipe(
              map(isEditMode => !isEditMode),
              takeUntil(this.onDestroy$)
          ),
          action: () => this.cancelEditNote()
        },
        {
          icon: 'save',
          hidden$: this.isEditNote$
            .pipe(
              map(isEditMode => !isEditMode),
              takeUntil(this.onDestroy$)
          ),
          action: () => this.saveNote()
        },
        {
          icon: 'delete',
          action: () => this.deleteNote()
        }
      ]
    });
  }

  private editNote() {
    this.isEditNote$.next(true);
    this.note$
      .pipe(first())
      .subscribe(note => this.editorContent = note.content);
  }

  private cancelEditNote() {
    this.isEditNote$.next(false);
  }

  private saveNote() {
    this.note$
      .pipe(take(1))
      .subscribe(note => {
        if (note) {
          note.content = this.editorContent;

          this.store.dispatch(new fromNoteActions.UpdateNote(note));
          this.isEditNote$.next(false);
        }
      });
  }

  private deleteNote() {
    this.note$
      .pipe(take(1))
      .subscribe(note => {
        if(note) {
          this.store.dispatch(new fromNoteActions.DeleteNote(note.id));
          this.router.navigateByUrl(`/notebook/${note.noteBookId}`);
        }
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
